.content-historique-admin-card-item {
  display: flex;
  align-items: center;
  background-color: #fff;
}
.date-historique-admin-card {
  width: 20%;
  padding: 20px;
}
.title-date-historique-admin-card {
  color: #667085;
  font-family: "Nunito";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.content-text-card-historique-admin {
  width: 80%;
  padding: 20px;
  border-left: 2px solid #e4e7ec;
}
.card-item-info-historique-admin {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.text-hour-historique-admin {
  color: #667085;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.text-info-historique-admin {
  color: #667085;
  font-family: "Nunito";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.btn-detail-historique-admin,
.btn-detail-historique-admin:hover {
  color: #22bbc0;
  font-family: "Nunito";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.container-body-modal-historique-admin {
  border-bottom: 1px solid #e4e7ec;
}
.container-body-modal-historique-admin:last-child {
  border-bottom: 0 !important;
}
.text-detail-historique-admin-modal {
  color: #828282;
  font-family: "Nunito";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.text-detail-historique-admin {
  color: #667085;
  font-family: "Nunito";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
