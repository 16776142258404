.custom-page-register {
    background-color: #E5E5E5;
}

.register-side-container {
    padding: 100px 30% 300px;
    display: grid;
    place-items: center;
}

.auth-form-container {
    padding: 3rem 2rem 6rem;
    background: #fff;
    box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.04),
      0px 4px 56px #0000000a;
    border-radius: 10px;
}

.form-label-auth-register {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #444150;
    display: flex;
}

.form-control:focus {
    box-shadow: none !important;
}

.auth-form-control {
    background: #fff;
    border-radius: 6px;
    padding: 15px 20px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #444150;
    border: 1px solid #d9d9d9;
}

.register-submit-btn {
    display: inline-block;
    width: 100%;
    padding: 10px;
    font-weight: 700;
    text-align: center;
    color: #fff;
    background: #4845E5;
    border-radius: 4px;
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 27px;
    text-align: center;
    text-decoration: none;
    text-transform: capitalize;
}
.react-custom-tel-input {
    background: #fff;
    border-radius: 6px;
    padding: 15px 20px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #444150;
    border: 1px solid #d9d9d9;
    height: inherit !important;
}
.text-message-auth {
    font-family: "Nunito";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: center;
    color: #4B4C4D;
}