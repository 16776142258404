.page-content-base-container {
  transition: 0.75s;
  overflow: auto;
  width: 100% !important;
  display: flex;
  justify-content: flex-end;
}

.user-dash-page-content-container {
  background: #ffffff;
  padding: 20px 34px;
}
.item-stat-dashboad-view {
  padding: 10px 20px;
  width: 100%;
  position: relative;
  align-items: center;
  box-shadow: 0px 12px 26px 0px #101e730f;
  border-radius: 10px;
  background: #fff;
}

.titre-item-stat-dashboad-view {
  font-family: "Lato";
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.10000000149011612px;
  color: #25282b;
}
.chiffre-item-stat-dashboad-view {
  font-family: "Lato";
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.10000000149011612px;
  color: #22bbc0;
}
.content-text-item-stat-dashboad-view {
  width: 100%;
}

.admin-page-container-layout {
  margin-top: 90px;
}
.content-stat-dashboard-view {
  display: flex;
  align-items: center;
  gap: 30px;
}
.content-chart-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-control-select-date-graph {
  background: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.07);
  border-radius: 100px;
  /* background: url(../../../assets/icons/Shape.png) 96% 50% / 13px no-repeat
    #fff; */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.4px;
  color: #252733;
  width: 300px;
  padding: 15px;
  border: 0;
}

.chart-item-admin-title {
  color: var(--neutral-black, #25282B);
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.2px;
}

.title-content-dash-admin {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: #000000;
}
.container-title-content-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title-content-dash-admin {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: #000000;
}
.content-graph-admin {
  background: #fff;
  padding: 20px;
  box-shadow: 0px 12px 26px 0px #101e730f;
  border-radius: 10px;
  position: relative;
}
.text-subtitle-graph {
  font-family: "Lato";
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.10000000149011612px;
  color: #a0a4a8;
}

.chart-item-admin-week-indicator-container {
  background: #ffffff;
  box-shadow: 0px 1.98221px 9.91103px rgba(0, 0, 0, 0.07);
  border-radius: 9.91103px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 27%;
  padding: 5px 15px;
}

.chart-item-admin-week-indicator-container .react-datepicker-wrapper {
  width: 90%;
}

.chart-item-admin-week-indicator-container .react-datepicker-wrapper .react-datepicker__input-container {
  width: 100%;
}

.chart-item-admin-week-indicator-container .react-datepicker-wrapper .react-datepicker__input-container .chart-item-admin-week-indicator {
  width: 100%;
  border: none !important;
}

.chart-item-admin-week-indicator {
  font-style: normal;
  font-weight: 400;
  font-size: 11.8932px;
  line-height: 18px;
  letter-spacing: 0.396441px;
  color: #252733;
}

/* .chart-item-admin-week-indicator-statut {
  width: 6px;
  height: 6px;
  background: #da7943;
  border-radius: 9.91103px;
} */

.fc-timeGridWeek-button.btn-type-date {
  border-radius: 9px 0px 0px 9px !important;
}

.btn-type-date {
  padding: 5px 15px;
  box-shadow: 0px 1.98221px 9.91103px rgba(0, 0, 0, 0.07);
  font-size: 13px !important;
}

.fc-timeGridDay-button.btn-type-date {
  border-radius: 0px 9px 9px 0px !important;
}

.fc-toolbar-chunk:first-child {
  display: flex;
  justify-content: space-between;
  width: 50%;
}
.fc-prev-button,
.fc-next-button {
  background: transparent !important;
  color: #22bbc0 !important;
  border: 0 !important;
}

.fc-dayGridMonth-button,
.fc-timeGridWeek-button,
.fc-timeGridDay-button {
  background: none !important;
  border: 0 !important;
  color: rgba(0, 0, 0, 0.70) !important;
  font-family: "Nunito" !important;
  font-size: 18.098px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.fc-button-active {
  background: #e5f3ff !important;
  background: #FFF !important;
  box-shadow: 0px 3.492783308029175px 3.492783308029175px 0px rgba(229, 179, 54, 0.10) !important;
  color: var(--bleu, #22bbc0) !important;
  font-family: "Nunito" !important;
  font-size: 18.098px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
.container-title-dash, .mon-doc-btn-filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}