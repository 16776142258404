.custom-top-title {
  color: var(--Vert, #22BBC0);
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
/* .filter-date-input{
  border: none;
} */

.filter-scope-btn {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid var(--gray-400, #DCDFE3);
  background: transparent;
  transition: .3s ease;
  color: #666E7D;
}

.filter-scope-btn:hover {
  background: var(--Vert, #22BBC0);
  color: #fff;
}

.custom-filtered-text {
  color: var(--gray-900, #3B3E45);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; 
}

.custom-filtered-text em {
  color: #585E6A;
  font-weight: 400; 
}

.format-rdv-content {
  width: 50px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--background, #F1F1F9);
}

.statut-rdv-content .visited {
  color: #00000066;
}

.statut-rdv-content .programmed {
  color: #017630;
}

.statut-rdv-content .nouveau {
  color: #22BBC0;
}

.statut-rdv-content .annuler {
  color: #D0775B;
}

.custom-detail-content .pap-card {
  background: #fff;
  padding: 2rem;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
}

.custom-detail-content .colgate-card {
  background: #fff;
  padding: 1.4rem;
  color: var(--grey-800, #3B4758);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 12px;
  height: 100%;
}

.pap-card .title {
  color: var(--grey-800, #3B4758);
  font-size: 21px;
  font-weight: 700;
}

.pap-card .name {
  color: var(--grey-700, #586A84);
  font-size: 14px;
  font-weight: 400;
}

.pap-card .attribution .sm-title {
  font-size: 12px;
  font-weight: 500;
}

.pap-card .attribution .sm-value, .colgate-card .sm-value {
  color: var(--Vert, #22BBC0);
}

.pap-card .attribution .statut .statut-value {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 25px; 
  gap: 3px;
  border-radius: 9px;
  /* background: #FBE5DF; */
  /* color: #D0775B; */
  padding: 0 6px;
}

.colgate-card .title {
  font-size: 18px;
  font-weight: 500;
}

.colgate-card .sm-title {
  font-size: 14px;
  font-weight: 700;
}

.colgate-card .sm-value {
  font-size: 13px;
}
.filtered-rdv-on-date-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}
.form-control-search {
  padding: 10px !important;
}