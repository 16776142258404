.bg-colgate {
  background: #fff;
  border-radius: 12px 12px 0 0;
}

.use-condition-item {
  padding: 1rem;
}

.use-condition-item .bordered {
  border-radius: 5px;
  border: 1px solid rgba(204, 204, 204, 0.50);
  padding: 2.2rem 1rem 1rem;
  position: relative;
}

.use-condition-title {
  color: var(--Vert, #22BBC0);
  font-family: Nunito;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.custom-condition-content {
  color: #000;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 33.584px; 
}

.use-condition-item .action-btn {
  position: absolute;
  right: .5rem;
  top: .5rem;
}

.use-condition-item .custom-btn-dots:is(:hover, :focus) {
  background: var(--Vert, #22BBC0);
  color: #fff;
}

container-radio-input {
	width: 100%;
	display: flex;
	align-items: center;
	padding: 20px 0;
}
.form-radio-input {
	display: flex;
	flex-wrap: wrap;
}
.radio-input label {
	margin-left: 10px;
	margin-right: 10px;
}
.radio-input label input {
	position: absolute;
	left: -9999px;
}
.radio-input label input:checked + span {
	background-color: #fff;
	border: 1px solid #22BBC0;
	box-sizing: border-box;
	border-radius: 10px;
}
.radio-input label input:checked + span:before {
	box-shadow: inset 0 0 0 0.4375em #22BBC0;
}
.radio-input label span {
	display: flex;
	align-items: center;
	padding: 0.375em 0.75em 0.375em 0.375em;
	border-radius: 10px;
	transition: 0.25s ease;
	border: 1px solid #e1e1e1;
	height: 45px;
	color: #586a84;
	font-family: "DM Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.radio-input label span:hover {
	background-color: #fff;
	border: 2px solid #22BBC0;
}
.radio-input label span:before {
	display: flex;
	flex-shrink: 0;
	align-items: center;
	justify-content: center;
	content: "";
	width: 1em;
	height: 1em;
	margin-right: 0.375em;
	transition: 0.25s ease;
	box-shadow: inset 0 0 0 0.125em #d9d9d9;
}
.radio-input label input:checked + span:before {
	content: "\2714";
	color: #fff;
	box-shadow: inset 0 0 0 0.4375em #22BBC0;
}
.title-permissions {
	margin-bottom: 0;
	color: var(--grey-800, #3b4758);
	font-family: "DM Sans";
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin: 0;
}
/* .format-pseudo{
	border-radius: 20px;
  	border: 1px solid ;
	padding: 9px;
} */



