.name-praticien-admin {
  font-family: "Lato";
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  color: #545675;
}
.poste-pratiien-admin {
  font-family: "Lato";
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  color: #9a9ba4;
}
.btn-view-profil-praticien {
  font-family: "Lato";
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0em;
  color: #22bbc0;
  padding: 15px;
  border: 1px solid #377dff50;
  border-radius: 10px;
  width: 100%;
}
.btn-view-profil-praticien:hover {
  background: #22bbc0;
  color: #fff;
}
.title-info-perso-praticien {
  font-family: "Lato";
  font-size: 15px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.20000000298023224px;
  color: #a0a4a8;
}

.custom-add-btn, .custom-extract-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  border-radius: 6px;
  background: #22BBC0;
  border: 1.4px solid transparent;
  color: #FFF;
  font-family: "Nunito";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.custom-add-btn {
  padding: 8px 14px;
}
.custom-extract-btn {
  padding: 10px;
 }

.custom-extract-btn-light {
  color: #000;
  font-weight: 500;
  border-radius: 6px;
  border: 0.5px solid #E2F0F1;
  background: #E2F0F1;
  padding: 10px;
}

.custom-add-btn:hover, .custom-extract-btn:hover {
  background: transparent;
  border-color: var(--Vert, #22BBC0);
  color: var(--Vert, #22BBC0);
}
