.td-photo-container, .td-photo-container img {
    width: 40px;
    height: 40px;
    border-radius: 50%;

}   

.btn-theme-admin {
    display: flex;
    padding: 8px 14px;
    justify-content: center;
    align-items: center;
    gap: 3px;
    flex: 1 0 0;
    border-radius: 6px;
    background: var(--Vert, #22BBC0);
    color: var(--White, #FFF);
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 100%;
    border: 1.4px solid transparent;
    transition: .4s ease;
}

.btn-theme-admin:hover {
    background: transparent;
    color: var(--Vert, #22BBC0);
    border-color: var(--Vert, #22BBC0);
}

.top-curved-container {
    display: flex;
    padding: 14px 24px;
    align-items: center;
    gap: 20px;
    border-radius: 12px 12px 0px 0px;
    background: #fff;
}

.custom-wrapper {
    padding: 1rem;
    border-radius: 12px;
    background: var(--White, #FFF);
    box-shadow: 0px 4px 20px 0px rgba(170, 169, 184, 0.10);
}

.custom-wrapper .custom-title {
    color: var(--grey-800, #3B4758);
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.custom-table-row-class {
    cursor: pointer;
}

.custom-wrapper .photo-wrapper {
    border-radius: 6px;
}

.custom-wrapper .photo-wrapper img {
    width: 100%;
    height: 280px;
    object-fit: fill;
}

.paiement-table thead, .paiement-table tbody {
    border-width: 0;
}

.paiement-table thead tr th {
    color: var(--grey-600, #7D8FA9);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-width: 0;
    text-align: center;
}

.paiement-table tbody tr td {
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-width: 0;
    text-align: center;
    vertical-align: middle;
}

.paiement-table tbody tr {
    border-width: 1px 0 0 0;
    border-color: var(--grey-600, #7D8FA9);
}

.paiement-table .payment-method-format {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-radius: 6px;
    border: 1px solid var(--BACKGROUND, #F7FCFE);
    background: var(--BACKGROUND, #F7FCFE);
}

.paiement-table .payment-method-format img {
    width: auto;
    height: 19px;
}

.paiement-table .statut-paiement-format {
    display: inline-flex;
    height: 30px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    height: 30px;
    padding: 8px;
    border-radius: 9px;
    background: var(--success-100, #CAFBEC);
    color: var(--success-600, #0DA678);
    font-family: Roboto;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.custom-rdv-status-title {
    font-family: Nunito;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.custom-rdv-status-title.incoming {
    color: var(--Bleu-e-sant, #4845E5);
}
.custom-rdv-status-title.past {
    color: var(--Purple-2, #BB6BD9);
}

.rdv-sm-item {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Nunito;
    font-style: normal;
    line-height: 20px;
    padding: .8rem;
    position: relative;
}

.rdv-sm-item:before {
    content: '';
    width: 5px;
    height: 5px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
}

.rdv-sm-item:after {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 2px;
    bottom: 0;
    margin: auto 0;
    border-radius: 50%;
}

.rdv-sm-item.past:before, .rdv-sm-item.past:after {
    background: var(--Green-1, #219653);
}
.rdv-sm-item.inprogress:before, .rdv-sm-item.inprogress:after {
    background: var(--Orange, #F2994A);
}
.rdv-sm-item.canceled:before, .rdv-sm-item.canceled:after {
    background: var(--Red, #EB5757);
}


.rdv-sm-item .name {
    font-size: 12px;
    font-weight: 600;
}

.rdv-sm-item .speciality {
    font-size: 10px;
    font-weight: 300;
}

.rdv-sm-item .hour {
    color: var(--typography-t-3, #AAA);
    text-align: right;
    font-size: 10px;
    font-weight: 500;
}

.rdv-sm-item .status {
    width: 16px;
    height: 16px;
    background: var(--background, #F1F1F9);
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.response-message-submit-btn-col {
    display: flex;
    width: 25%;
    float: inline-end;
}

.ql-container.ql-snow {
    min-height: 8rem;
}