.container-header {
    background: #FFF;
    box-shadow: 0px 4px 20px 0px #F1F1F9;
}

.form-control-checkbox {
    zoom: 2.5;
    accent-color: #22bbc0 ;
}

