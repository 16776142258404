.custom-tabs-content, .custom-sub-title-content {
  border-radius: 0px 0px 12px 12px;
  background: #FFF;
  padding: .5rem;
}

.custom-tabs-content ul {
  display: flex;
  list-style: none;
  align-items: center;
  gap: 10px;
}

.custom-tabs-content ul .tab-item {
  color: var(--Gris, #4B4C4D);
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 5px 13px;
  cursor: pointer;
}

.custom-tabs-content ul .tab-item-active {
  color: var(--Vert, #22BBC0);
  font-weight: 700;
}

.custom-tab-title {
  color: var(--Noir, #000);
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.icon-edit-info-profil {
  position: absolute;
  right: 1.5rem;
  top: 2.2rem;
}

.photo-wrapper {
  cursor: pointer;
  transition: all .5s ease;
}

.photo-wrapper:hover .picture-overlay {
  display: grid;
}

.picture-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 6px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), lightgray 50% / cover no-repeat;
  opacity: 0.6;
  display: grid;
  place-items: center;
  color: #fff;
  font-size: 55px;
  display: none;
}

.custom-profil-content .custom-wrapper {
  height: 100%;
}

.input-add-avatar {
  display: none;
}

.label-input-test {
  display: flex;
  justify-content: center;
  align-items: center;
}