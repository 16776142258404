.empty-header {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  padding: 50px;
}

.auth-right-side-container {
  padding: 100px 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-form-container {
  padding: 20px;
  background: #fff;
  box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.04),
    0px 4px 56px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
}

.title-auth-form-text {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 29px;
  line-height: 38px;
  color: rgba(0, 0, 0, 0.85);
}

.auth-form-control {
  background: #fff;
  border-radius: 6px;
  padding: 15px 20px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #444150;
  border: 1px solid #d9d9d9;
}

.auth-form-control:focus {
  box-shadow: none;
}

.form-group.auth-form-group {
  margin-bottom: 40px;
}

.content-forget-password-link {
  display: flex;
  justify-content: flex-end;
}

.forget-password-link {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #000000b4;
  text-decoration: none;
}

.forget-password-link:hover {
  text-decoration: underline;
  color: #22BBC0;
  font-weight: bold;
}

.auth-form-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: rgba(15, 32, 52, 0.4);
}

.auth-submit-btn {
  background: #22BBC0;
  border-radius: 4px;
  width: 100%;
  padding: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
}

.auth-submit-btn:hover {
  background: #fff;
  color: #22BBC0;
  border: 1px solid #22BBC0;
  text-decoration: none;
}

.auth-row.row .auth-col {
  padding: 0 !important;
}

.form-control:focus {
  box-shadow: none !important;
}

.form-label-auth-login {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #444150;
  display: flex;
}

.auth-form-message {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #0f2034;
}

.show-hide-password {
  position: absolute;
  right: 2%;
  color: #22BBC0;
  top: 43px;
  font-size: 20px;
}

.text-message-forgepassword {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #333333;
}
.form-label-auth-login {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #555555;
  display: flex;
}
